// Global Stlyes
html, body {
	height: 100%;
	font-family: 'DINCondensed-Bold';
	font-size: 1.2em;
	line-height: 1.2em;
}

h1 {
	font-size: 2em;
	text-transform: uppercase;
	margin-top: 70px;
	margin-bottom: 0;
}
h4 {
	text-transform: uppercase;
}
p {
	margin-top:0;
	margin-bottom: 25px;
}

.custom-primary {
	color: #148b29;
}

.btn {
	text-transform: uppercase;
	padding-top: 12px;
}
//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	
}

//Tablet 768+
@include media-breakpoint-up(md) {
	
}

// Desktop 992+
@include media-breakpoint-up(lg) {
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}