// Global Stlyes
.backstretch-color {
	width: 100%;
	height: 100%;
	display: block;
	position: fixed;
	z-index: 0;
	background: #000;
	opacity: 0.6; 
}

.backstretch {
	filter: grayscale(100%);
}

//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	
}

//Tablet 768+
@include media-breakpoint-up(md) {
	
}

// Desktop 992+
@include media-breakpoint-up(lg) {
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}