.svg-item {
	width: 80%;
	margin: 0 auto;
}
/* Style */
#ferrari-logo * {
  fill-opacity: 0;
  transition: fill-opacity 1s;
}

#ferrari-logo.finished * {
  fill-opacity: 1;
}
//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
}

//Tablet 768+
@include media-breakpoint-up(md) {
	
}

// Desktop 992+
@include media-breakpoint-up(lg) {
.svg-item {
	width: 50%;
}
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}